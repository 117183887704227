import styled from "@emotion/styled"
import React from "react"
import { keyframes, css } from "@emotion/core"

export const sizes = {
  desktop: 1600,
  tablet: 900,
  mobile: 576,
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  const emSize = sizes[label]
  acc[label] = (...args) => css`
    @media (max-width: ${emSize}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})


export const palette = {
  black: "#101010",
  black_40: "rgba(16, 16, 16, 0.4)",
  black_20: "rgba(16, 16, 16, 0.2)",
  white: "#ffffff",
  off_white: "#F0EFEA",
  blue: "#047692",
  sky: "#76B3C2",
  red: "#C74511",
  // TO REMOVE: Old colors
  aqua: "#1ccdd0",
  dark_blue_grey: "#10213c",
  dark_blue: "#082048",
  robin_egg_blue_10: "rgba(141, 235, 237, 0.1)",
  grey: "rgba(0, 0, 0, 0.5)",
}

export const h1 = styled.h1`
  font-weight: 100;
  font-size: 324px;
  line-height: 1;
  /* letter-spacing: 0.2rem; */
  color: ${palette.black};
  margin-left: -25px;
  ${media.tablet`
    font-size: 160px;
    margin-left: -12px;
  `}
  ${media.mobile`
    font-size: 100px;
    margin-left: -8px;
  `}
`

export const h2 = styled.h2`
  font-weight: 100;
  font-size: 180px;
  line-height: 1;
  /* letter-spacing: 0.2rem; */
  margin-left: -5px;
  color: ${palette.black};
  ${media.desktop`
    font-size: 100px;
    // margin-left: -7px;
  `}
  ${media.tablet`
    font-size: 75px;
    // margin-left: -7px;
  `}
  ${media.mobile`
    font-size: 64px;
    // margin-left: -5px;
  `}
`

export const h3 = styled.h3`
  font-weight: 100;
  font-size: 120px;
  line-height: 1;
  /* letter-spacing: 0.2rem; */
  color: ${palette.black};
  margin-left: -9px;
  ${media.desktop`
    font-size: 72px;
    margin-left: -5px;
  `}
  
  ${media.tablet`
    font-size: 72px;
    margin-left: -5px;
  `}
  ${media.mobile`
    font-size: 52px;
    margin-left: -4px;
  `}
`

export const h4 = styled.h4`
  font-weight: 100;
  font-size: 55px;
  line-height: 1.1;
  /* letter-spacing: 0.2rem; */
  color: ${palette.black};
  margin-left: -4px;
  ${media.desktop`
    font-size: 36px;
    margin-left: -3px;
  `}
  ${media.tablet`
    font-size: 36px;
    margin-left: -3px;
  `}
  ${media.mobile`
    font-size: 28px;
    margin-left: -2px;
  `}
`

export const h4_ = css`
  font-weight: 100;
  font-size: 55px;
  line-height: 1.1;
  /* letter-spacing: 0.2rem; */
  color: ${palette.black};
  ${media.desktop`
    font-size: 36px;
    margin-left: -3px;
  `}
  ${media.tablet`
    font-size: 36px;
    margin-left: -3px;
  `}
  ${media.mobile`
    font-size: 28px;
    margin-left: -2px;
  `}
`

export const h5 = styled.h5`
  font-weight: 100;
  font-size: 25px;
  line-height: 1.1;
  /* letter-spacing: 0.2rem; */
  color: ${palette.black};
  ${media.tablet`
    font-size: 22px;
  `}
  ${media.mobile`
    font-size: 20px;
  `}
`

export const h5_ = css`
  font-weight: 100;
  font-size: 25px;
  line-height: 1.1;
  /* letter-spacing: 0.2rem; */
  color: ${palette.black};
  ${media.tablet`
    font-size: 22px;
  `}
  ${media.mobile`
    font-size: 20px;
  `}
`

export const text = css`
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
  /* letter-spacing: 0.2rem; */

  a {
    text-decoration: underline;
  }
`

export const text_ = styled.div`
  ${text};
`

export const p = styled.p`
  ${text};
  color: ${palette.black};
`

export const text_small = css`
  font-weight: 300;
  font-size: 14px;
  line-height: 1.1;
  /* letter-spacing: 0.2rem; */

  a {
    text-decoration: underline;
  }
`

export const text_small_ = styled.div`
  ${text_small};
`

export const input_line = styled.input`
  ${text};
  outline: none;
  line-height: 2;
  border: none;
  border-bottom: solid 1px ${palette.black_40};
  border-radius: 0 !important;
  background-color: transparent;
  color: ${palette.black};
  ::placeholder {
    color: ${palette.black_40};
  }
  :focus {
    border-bottom: solid 1px ${palette.black};
    color: ${palette.black};
  }
  :hover {
    color: ${palette.black};
    ::placeholder {
      color: ${palette.black};
    }
  }
  .error {
    border-bottom: solid 1px ${palette.red};
    :empty {
      color: ${palette.red};
    }
  }
`

export const input_line__white = styled.input`
  ${text};
  outline: none;
  line-height: 2;
  border: none;
  border-bottom: solid 1px ${palette.white};
  background-color: transparent;
  color: ${palette.white};
  ::placeholder {
    color: ${palette.white};
  }
  :focus {
    border-bottom: solid 1px ${palette.white};
    color: ${palette.white};
  }
  :hover {
    color: ${palette.white};
    ::placeholder {
      color: ${palette.white};
    }
  }
  .error {
    border-bottom: solid 1px ${palette.red};
    :empty {
      color: ${palette.red};
    }
  }
`

export const input_text = styled.textarea`
  ${text};
  border: solid 1px ${palette.black_40};
  color: ${palette.black};
  background-color: transparent;
  outline: none;
  padding: 14px;
  resize: none;

  ::placeholder {
    color: ${palette.black_40};
  }
  :focus {
    border: solid 1px ${palette.black};
    color: ${palette.black};
    outline: none;
  }
  :hover {
    color: ${palette.black};
    outline: none;
    ::placeholder {
      color: ${palette.black};
    }
  }
  // .error {
  //   border-bottom: solid 1px ${palette.red};
  //   :empty {
  //     color: ${palette.red};
  //   }
  // }
`

export const button = css`
  ${text};
  border-radius: 40px;
  border: none;
  padding: 13px 20px 11px 20px;
  ${media.mobile`
    padding: 10px 16px 8px 16px;
    font-size: 13px;
    border-radius: 32px;
  `}
`

export const button_dark = styled.button`
  ${button};
  color: white;
  background-color: ${palette.blue};
  :hover {
    background-color: ${palette.black};
  }
  :disabled {
    background-color: ${palette.black_20};
  }
`

export const button_light = styled.button`
  ${button};
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
  :hover {
    background-color: ${palette.white};
  }
  :disabled {
    color: ${palette.black_40};
  }
`

// TO REMOVE
export const body_ = css`
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
  /* letter-spacing: 0.2rem; */
  color: ${palette.black};
`

export const body = styled.p`
  ${body_};
`

export const small_ = css`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: auto;
  /* color: ${palette.dark_blue_grey}; */
  color: ${palette.grey};
`

export const small = styled.span`
  ${small_}
`

export const link_ = css`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1;
  letter-spacing: 0.05rem;
  color: ${palette.aqua};
`

export const link = styled.a`
  ${link_}
`

export const center = css`
  margin: 0 auto;
  padding: 0 33px;
  
  ${media.tablet`
      padding: 0 18px;
  `}
  ${media.mobile`
      padding: 0 15px;
  `}
`

const ParagraphText = styled(body)`
  color: ${palette.grey};
  white-space: pre-line;
  text-align: left;
  :not(:last-of-type) {
    margin-bottom: 2rem;
  }
`

export const GridNoMargin = css`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 26px;
  ${media.tablet`
    gap: 20px;
  `}
  ${media.mobile`
    gap: 15px;
  `}
`

export const GridMargin = css`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding-left: 33px;
  padding-right: 33px;
  gap: 26px;

  ${media.tablet`
    gap: 20px;
    padding-left: 18px;
    padding-right: 18px;
  `}
  ${media.mobile`
    gap: 15px;
    padding-left: 15px;
    padding-right: 15px;
  `}
`

export const Paragraph = ({ children }) =>
  children
    .split("\n")
    .map(x => String(x).replace(/\u200B/g, ""))
    .filter(x => x !== " " || x !== "")
    .map((text, key) => <ParagraphText key={key}>{text}</ParagraphText>)

export const fadeIn = time => keyframes`
    0% { opacity:0; }
    ${time}% { opacity:0; }
    100% { opacity:1; }
`

export const fadeInSlide = (time, distance = 30) => keyframes`
    0% { opacity:0; transform: translateY(${distance}px) }
    ${time}% { opacity:0; transform: translateY(${distance}px) }
    100% { opacity:1; transform: translateY(0px) }
`

export const slideIn = (time, distance = 30) => keyframes`
    0% {transform: translateY(${distance}px) }
    ${time}% {transform: translateY(${distance}px) }
    100% { transform: translateY(0px) }
`
